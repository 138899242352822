import React, { Component } from "react";
import { connect } from "react-redux";

import Api from "api/requests";
import "./index.scss";
import './print.scss';
import home_logo from 'assets/logos/home-page-logo.png';

class Home extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {

  }

  logout = () => {
    Api.logout();
  };

  render() {
    return (
      <div className="home">
        <div className="options">
          <button className="logout" onClick={this.logout}>התנתקות</button>
        </div>
        <div className={"main-content"}>
          <h2 className={"title"}>ממשק מנהלי חניונים</h2>
          <div className={"logo-wrapper"}>
            <img src={home_logo} className="logo" alt="logo"/>
          </div>
        </div>
      </div>
    );
  }
}

//add GD categories and deviceState to props
const mapStateToProps = (store) => {
  return {
  };
};
//map a doLgin function to props
const mapDispatchToProps = (dispatch) => {
  return {
    setLoader: (state) => dispatch(Actions.setLoader(state)),
  };
};

//connect to redux store - maps dispatch and redux state to props
export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false,
})(Home);
