import React, {Component, useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";

import Validate from "app/validation/validation";
import Actions from "redux/actions";

import CustomDatePicker from "components/forms/CustomDatePicker/CustomDatePicker";
import Select from "components/forms/select";

import "./index.scss";
import Api from "api/requests";
import ParkingSummary from "containers/ParkingSummary/ParkingSummary";
import TextInput from "../../components/forms/textInput";
import SelectAutocomplete from "../../components/forms/selectAutocomplete";

function ManualCarAction() {
    const dispatch = useDispatch();
    const parkingLots = useSelector(store => store.gd.parkingArr);
    const allowedParkingLots = useSelector(store => store.userData.parking_manager);
    const actionForm = useSelector(store => store.actionForm);
    const carCheckForm = useSelector(store => store.carCheckForm);
    const carActionMessage = useSelector(store => store.actionMessage);
    const carCheckMessage = useSelector(store => store.carCheckMessage);
    const [carParks, setCarParks] = useState([]);

    useEffect(() => {
        const carparks = [];
        for (const key in parkingLots) {
            const parkingLot = parkingLots[key];
            const isAllowed = isAllowedParkingLot(key);
            if (isAllowed) {
                const carPark = {
                    value: parkingLot.title,
                    id: key,
                };
                carparks.push(carPark);
            }
        }
        setCarParks(carparks);
    }, [])

    const isAllowedParkingLot = (id) => {
        for (const key in allowedParkingLots) {
            const allowedParkingLot = allowedParkingLots[key];
            if (Number(allowedParkingLot) === Number(id)) {
                return true;
            }
        }
        return false;
    };

    const onChangeActionForm = (field, value) => {
        dispatch(Actions.updateActionForm({[field]: value}))
    };

    const onChangeCarCheckForm = (field, value) => {
        dispatch(Actions.updateCarCheckForm({[field]: value}))
    };

    const onActionFormSubmit = (e) => {
        e.preventDefault();
        const payload = getCloseCarParkingPayload();
        dispatch(Actions.setLoader(true))

        Api.closeCarParking({payload: payload}).then(() => {
            dispatch(Actions.setLoader(false))
        });
    };

    const onCarCheckFormSubmit = (e) => {
        e.preventDefault();
        const payload = getCarCheckPayload();
        dispatch(Actions.setLoader(true))

        Api.checkCarParking({payload: payload}).then(() => {
            dispatch(Actions.setLoader(false))
        });
    };

    const toTimestamp = (strDate) => {
        const dt = Date.parse(strDate);
        return dt / 1000;
    };

    const getCloseCarParkingPayload = () => {
        return {
            licenseNumber: actionForm.licenseNumber,
            exitTs: toTimestamp(actionForm.exitTime),
            parkingId: actionForm.carParkId,
        };
    }

    const getCarCheckPayload = () => {
        return {
            licenseNumber: carCheckForm.licenseNumber,
        };
    }

    const logout = () => {
        Api.logout();
    };

    return (
        <div className="home">
            <div className="options">
                <button className="logout" onClick={logout}>התנתקות</button>
            </div>
            <div className={"section-container"}>
                <div className={"section-title"}>ביצוע פעולת יציאה ידנית:</div>
                <div className={"inputs-container"}>
                    <div className="inputs-wrapper">
                        <TextInput
                            type={"text"}
                            className="field"
                            label={"לוחית רישוי"}
                            labelClassName={"input-label"}
                            placeholder={""}
                            value={actionForm.licenseNumber}
                            onChange={onChangeActionForm}
                            name={"licenseNumber"}
                        />
                        <CustomDatePicker
                            className="field"
                            label={"שעת יציאה:"}
                            value={actionForm.exitTime}
                            onChange={onChangeActionForm}
                            name={"exitTime"}
                            isAddTime={true}
                        />
                        <SelectAutocomplete
                            label={"חניון"}
                            options={carParks}
                            selectedId={actionForm.carParkId}
                            onChange={onChangeActionForm}
                            name={"carParkId"}
                            fieldname={"value"}
                            className={"choose-parking-garage field"}
                        />
                    </div>

                    <button className={"send-btn"} onClick={onActionFormSubmit}>
                        שלח
                    </button>
                </div>
                <div className={"api-message"}>{carActionMessage}</div>
            </div>

            <div className={"section-container"}>
                <div className={"section-title"}>בדיקת מספר רכב:</div>
                <div className={"inputs-container"}>
                    <div className="inputs-wrapper">
                        <TextInput
                            type={"text"}
                            className={"field single-field"}
                            label={"לוחית רישוי"}
                            labelClassName={"input-label"}
                            placeholder={""}
                            value={carCheckForm.licenseNumber}
                            onChange={onChangeCarCheckForm}
                            name={"licenseNumber"}
                        />
                    </div>

                    <button className={"send-btn"} onClick={onCarCheckFormSubmit}>
                        חיפוש
                    </button>
                </div>
                <div className={"api-message"}>{carCheckMessage}</div>
            </div>
        </div>
    );
}

export default ManualCarAction;
