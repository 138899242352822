import { createSlice } from '@reduxjs/toolkit'

let Slices = [];
let dataReducers = {};
let dataActions  = {};

export const gdSlice = createSlice({
    name: 'gd',
    initialState: false,
    reducers: {
        setGd: (state, action) => action.payload
    },
})
Slices.push(gdSlice);

/*---------------------------------------------------------------*/

export const userSlice = createSlice({
    name: 'userData',
    initialState: false,
    reducers: {
        setUser: (state, action) => action.payload
    },
})

// Action creators are generated for each case reducer function
Slices.push(userSlice);

/*---------------------------------------------------------------*/

export const metaTagsSlice = createSlice({
    name: 'metaTags',
    initialState: false,
    reducers: {
        setMetaTags: (state, action) => action.payload
    },
})

// Action creators are generated for each case reducer function
Slices.push(metaTagsSlice);

/*---------------------------------------------------------------*/

export const parkingSummarySlice = createSlice({
    name: 'parkingSummary',
    initialState: false,
    reducers: {
        setParkingSummary: (state, action) => action.payload
    },
})

// Action creators are generated for each case reducer function
Slices.push(parkingSummarySlice);

export const actionMessageSlice = createSlice({
    name: 'actionMessage',
    initialState: "",
    reducers: {
        setActionMessage: (state, action) => action.payload
    },
})

// Action creators are generated for each case reducer function
Slices.push(actionMessageSlice);

export const carCheckMessageSlice = createSlice({
    name: 'carCheckMessage',
    initialState: "",
    reducers: {
        setCarCheckMessage: (state, action) => action.payload
    },
})

// Action creators are generated for each case reducer function
Slices.push(carCheckMessageSlice);


//build export objects
for(const Slice of Slices) {
    dataActions  = {...dataActions, ...Slice.actions};
    let reducer  = {[Slice.name]:Slice.reducer};
    dataReducers = {...dataReducers, ...reducer};
}

export { dataActions };
export { dataReducers };