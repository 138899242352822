import React, { useState, useRef, useEffect } from "react";

import DropDown from "../../../assets/icons/drop down.svg";
import CheckMark from "assets/icons/check-mark.svg";
import close from "assets/icons/close-icon.svg";
import "./index.scss";

function useOutsideAlerter(ref, onClickOutside) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside();
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

function SelectAutocomplete(props) {
    const {
        label = "",
        options,
        disabled = false,
        onChange,
        name,
        dropDownImg = DropDown,
        className = "",
        selectedId,
        fieldname = "value",
    } = props;
    const wrapperRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [highlightedItem, setHighlightedItem] = useState(-1);

    useOutsideAlerter(wrapperRef, onClickOutside);

    const handleClick = (event) => {
        // hanles when user clicks the button
        const newState = !isOpen;
        setIsOpen(newState);
    };

    function onClickOutside() {
        setIsOpen(false);
    }

    const handleKeyUp = (event) => {
        let highlighted_item = highlightedItem;

        switch (event.key) {
            case "ArrowDown":
                highlighted_item + 1 > filteredOptions.length - 1
                    ? (highlighted_item = 0)
                    : highlighted_item++;

                setHighlightedItem(highlighted_item);
                break;

            case "ArrowUp":
                highlighted_item - 1 < 0
                    ? (highlighted_item = filteredOptions.length - 1)
                    : highlighted_item--;
                setHighlightedItem(highlighted_item);

                break;

            case "Enter":
                setHighlightedItem(-1);
                const selected_option = filteredOptions[highlighted_item];

                //if (!checkForIdInList(highlightedItem)) {
                    onChange(name, Number(selected_option.id));
                //}
                break;

            case "Escape":
                setIsOpen(false);
                setHighlightedItem(0);
                break;
            default:
                break;
        }
    };
    const handleOptionClick = (event) => {
        // handles when user clicks an option
        onChange(name, Number(event.target.id));
        onClickOutside();
        const selectedOption = options.find(obj => obj.id === event.target.id);
        const title = selectedOption.value
        setSelectedOption(title);
    };

    function getList(options) {
        const list = [];
        options.map((item, index) => {
            let hightlightedOption = "";

            if (index === highlightedItem) {
                hightlightedOption = " highlight";
            }

            list.push(
                <li
                    className={"select_option " + hightlightedOption}
                    key={index}
                    id={item.id}
                    onMouseDown={handleOptionClick}
                >
                    {item[fieldname]}
                </li>
            );
        });
        return list;
    }

    const activeClass = isOpen ? "active" : "";
    const disabledClass = disabled ? "disabled" : "";

    return (
        <div className={"multi-select-autocomplete-wrapper " + className}>
            <div
                className={"select-wrapper " + activeClass + " " + disabledClass}
                onKeyUp={handleKeyUp}
                ref={wrapperRef}
            >
                <input
                    className="input no-caret"
                    placeholder={label}
                    onClick={handleClick}
                    value={selectedOption}
                    onChange={(e) => e.preventDefault()}
                />
                <img src={dropDownImg} className="dropdown-img"></img>

                <ul className="dropdown-menu">
                    {getList(options)}
                </ul>
            </div>
        </div>
    );
}

export default SelectAutocomplete;
