import "./index.scss";
import React, {Component, useState} from "react";

import AnimatedInput from "../../../../components/forms/animated_input";
import Actions from "../../../../redux/actions";
import Validate from "../../../../app/validation/validation";
import {connect} from "react-redux";

class Tool extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first_try: true,
            form: {
                full_name: {
                    valid: false,
                    rules: ["full_name", "not_empty"],
                    errMsg: "",
                },
            },
        };
    }

    handleInputChange = (e) => {
        let name = e.target.name;
        let val = e.target.value;
        if (e.target.type == "checkbox") {
            val = e.target.checked;
        }
        let validationObj = Validate(val, this.state.form[name].rules);

        let new_state = { ...this.state };
        new_state.form[name].valid = validationObj.valid;
        new_state.form[name].errMsg = validationObj.msg;

        this.setState(new_state);
        this.props.updateForm({ [name]: val });
    };


    showError = (field) => {
        return !this.state.first_try && !this.state.form[field].valid;
    };

    render() {
        const Form = this.props.Form;
        return (
            <div className="tool-wrapper with-preview">
                <div className="left">
                    <div className="component">
                        <div className="component-title">Animated input</div>
                        <div className="component-usage">Simple input with animated placeholder</div>
                    </div>
                    <div className="import-strip">
                        <div className="label">
                            <b>Location:</b>
                        </div>
                        <div className="label">
                            /components/ forms/ animated_input
                        </div>
                    </div>
                    <div className="import-strip">
                        <div className="label">
                            <b>Actions:</b>
                        </div>
                        <div className="label">
                            onChange  - Will check if the user input is valid
                            showError - Will show error if user input is invalid
                        </div>
                    </div>
                    <div className="code-sample">
                        <code>
                            {`  <AnimatedInput
                className="field"
                value={Form.full_name}
                name={"full_name"}
                placeholder={"שם מלא"}
                autocomplete={false}
                onChange={this.handleInputChange}
                showError={this.showError("full_name")}
                errorMessage={this.state.form.full_name.errMsg}
            />`}
                        </code>
                    </div>
                </div>
                <div className="right">
                    <AnimatedInput
                        className="field"
                        value={Form.full_name}
                        name={"full_name"}
                        placeholder={"שם מלא"}
                        autocomplete={false}
                        onChange={this.handleInputChange}
                        showError={this.showError("full_name")}
                        errorMessage={this.state.form.full_name.errMsg}
                    />
                </div>
            </div>
        );
    }
}

//connect to redux store
const mapStateToProps = (store) => {
    return {
        deviceState: store.deviceState,
        Form: store.contactForm,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateForm: (payload) => dispatch(Actions.updateContactForm(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
    pure: false,
})(Tool);