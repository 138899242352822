import React from "react";
import ParkingLotSummary from "./ParkingLotSummary/ParkingLotSummary";
import "./ParkingSummary.scss";
function ParkingSummary(props) {
  const { data, parkings } = props;

  function getParkingLotNameById(id) {
    for (let i = 0; i < parkings.length; i++) {
      if (Number(id) === Number(parkings[i].id)) {
        return parkings[i].value;
      }
    }
    return "";
  }

  return (
    <div className="parking-summary-wrapper">
      <div className="short-summary">
        <div className="total-entries-amount total">
          <span className="title">סה"כ כניסות:</span>
          <span className="text">{data.total_entries_amount}</span>
        </div>
        <div className="total-exits-amount total">
          <span className="title">סה"כ יציאות:</span>
          <span className="text">{data.total_exits_amount}</span>
        </div>
        <div className="total-force-exits-amount total">
          <span className="title">סה"כ יציאות אוטומטיות:</span>
          <span className="text">{data.total_force_exits_amount}</span>
        </div>
        <div className="total-earnings-amount total">
          <span className="title">סה"כ הכנסות:</span>
          <span className="text">{data.total_entries_total_earnings}</span>
        </div>
        <div className="total-refunds-amount total">
          <span className="title">סה"כ זיכויים:</span>
          <span className="text">{data.total_credits_amount}</span>
        </div>
        <div className="total-earnings-amount total">
          <span className="title">ממוצע דקות חניה:</span>
          <span className="text">{data.total_minutes_time}</span>
        </div>
        <span className="data-explaination">* הזיכויים המוצגים הם עבור תאריכי החיפוש בלבד.</span>
      </div>

      <div className="parking-lot-summary-wrapper">
        {Object.keys(data.parking).map((key, idx) => {
          const name = getParkingLotNameById(key);

          const parkingsEnties = data.parking[key];
          const parkingsTotalAvgMinutes = data.total_minutes_time_parking[key];

          return (
            <ParkingLotSummary
              parkingLotName={name}
              key={key}
              data={parkingsEnties}
              parkingsTotalAvgMinutes={parkingsTotalAvgMinutes}
            />
          );
        })}
      </div>
    </div>
  );
}

export default ParkingSummary;
