import React, {useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';

//import actions
import Actions from 'redux/actions';
import close from 'assets/icons/close-window-icon.svg';
import './index.scss';

export default function BurgerMenu(props) {

    const state = useSelector(store => store.burgerState);
    const dispatch = useDispatch();

    const handleCloseClick = () => {
        dispatch(Actions.setBurger(false));
    }

    useEffect(() => {
        document.body.style.overflow = state ? 'hidden' : 'auto';
    }, [state])

    return (
        <div className={"burger-menu-wrapper " + (state ? 'active' : '')}
             onClick={handleCloseClick}>
            <div className="burger-menu">
                <div className={"close-icon"} onClick={handleCloseClick}>
                    <img className={"close-img"} src={close} alt={""}/>
                </div>
                <ul className="burger-menu-list">
                    {props.data.map((item, index) =>
                        <li className="burger-menu-item" key={"burger-item-" + index}>
                            <NavLink exact
                                     to={item.route}
                                     activeClassName='active'
                                     className="btn burger-menu-link"
                                     onClick={handleCloseClick}>
                                <span>{item.text}</span>
                            </NavLink>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    )
}