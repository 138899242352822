import React, {Component} from 'react';
import { connect }        from 'react-redux';
import Actions from 'redux/actions';
import './index.scss';
import ComponentLoader from 'components/common/loaders/component';

class Tool extends Component {

    constructor(props){
        super(props);

        this.state = {
            loaderState: false
        }
    }

    doSomeAsyncThing = () => {
        this.setState({loaderState: true});
        setTimeout(()=>{
            this.setState({loaderState: false})
        },3000);
    }

    render() {
        return (
             <div className="tool-wrapper">
                <div className="component">
                    <div className="component-title">Component Loader</div>
                    <div className="component-usage">
                        Simple component loader controlled by the component's state. Implemented in test loader button.
                    </div>
                </div>
                <div className="import-strip">
                    <div className="label">import ComponentLoader from 'components/common/loaders/component';</div>
                </div>
                <div className="import-strip">
                    <div className="label"> <b> Props: </b></div>
                    <div className="label">loaderState(<i>boolean</i>)</div>
                </div>
                <div className="code-sample">
                    <code>
                        {`<ComponentLoader />`}
                    </code>
                </div>
                <div className="note">
                    <div className="note-label">note:</div>
                    <span>It's only a basic loader, tailor it to your specific project</span>
                </div>
                <button className="button" onClick={ this.doSomeAsyncThing }>{this.state.loaderState ? <ComponentLoader loaderState={this.state.loaderState}/> : 'test loader'}</button>
            </div>
        );
    }
}

//add GD categories and deviceState to props
const mapStateToProps = store => {
    return {
        deviceState: store.deviceState,
    }
}
//map a doLgin function to props
const mapDispatchToProps = dispatch => {
    return {
        setLoader: (payload) => dispatch(Actions.setLoader(payload))
    }
}

//connect to redux store - maps dispatch and redux state to props
export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(Tool);
