import React from "react";
import "./ParkingLotSummary.scss";

const daysOfTheWeek = {
  0: "ראשון",
  1: "שני",
  2: "שלישי",
  3: "רביעי",
  4: "חמישי",
  5: "שישי",
  6: "שבת",
};

function ParkingLotSummary(props) {
  const { parkingLotName, data, parkingsTotalAvgMinutes} = props;
  const shouldShowSummary = () => {
    const entries = Object.keys(data);
    const totalEntriesAmount = entries.reduce(function (previous, key) {
      return previous + (data[key].parkings_entries_amount ?? 0);
  }, 0);
      const totalExitsAmount = entries.reduce(function (previous, key) {
          return previous + (data[key].parkings_exits_amount ?? 0);
  }, 0);
      const totalForceExitsAmount = entries.reduce(function (previous, key) {
          return previous + (data[key].parkings_force_exits_amount ?? 0);
  }, 0);
    return totalEntriesAmount > 0 || totalExitsAmount > 0 || totalForceExitsAmount > 0;
  }

  const getComputedRow = () => {
    const entries = Object.keys(data);
    const totalEntriesAmount = entries.reduce(function (previous, key) {
      return previous + (data[key].parkings_entries_amount ?? 0);
  }, 0);
      const totalExitsAmount = entries.reduce(function (previous, key) {
          return previous + (data[key].parkings_exits_amount ?? 0);
  }, 0);
      const totalForceExitsAmount = entries.reduce(function (previous, key) {
          return previous + (data[key].parkings_force_exits_amount ?? 0);
  }, 0);
    const totalParkingsPayment =  entries.reduce(function (previous, key) {
      return previous + (data[key].parkings_total_payment ?? 0);
  }, 0);
  //   const totalAvgMinutes =  entries.length > 0 ? entries.reduce(function (previous, key) {
  //     return previous + (data[key].minutes_per_car ?? 0);
  // }, 0) / entries.length : 0;
    const totalSumRefunds =  entries.reduce(function (previous, key) {
      return previous + (data[key].credits_amount ?? 0);
  }, 0);
    const totalSumDiscounts =  entries.reduce(function (previous, key) {
      return previous + (data[key].discount ?? 0);
  }, 0);
    return (
      <div className="row">
      <div className="body-block block day-name">סה״כ</div>
      <div className="body-block block sum-entries">{totalEntriesAmount}</div>
      <div className="body-block block sum-entries">{totalExitsAmount}</div>
      <div className="body-block block sum-entries">{totalForceExitsAmount}</div>
      <div className="body-block block avg-minutes">{parkingsTotalAvgMinutes.toFixed(2)}</div>
      <div className="body-block block sum-refunds">{totalSumRefunds}</div>
      <div className="body-block block sum-discounts">{totalSumDiscounts}</div>
      <div className="body-block block sum-payments">
        {totalParkingsPayment}
      </div>
    </div>
    )
  }

  if (!shouldShowSummary()) {
    return <></>;
  }

  return (
    <div className="parking-lot-data">
      <h3 className="parking-lot-title">{parkingLotName}</h3>
      <div className="parking-lot-table">
        <div className="table-header blocks-wrapper">
          <div className="header-block block day-name">יום</div>
          <div className="header-block block sum-entries">סה״כ כניסות</div>
          <div className="header-block block sum-exits">סה״כ יציאות</div>
          <div className="header-block block sum-force-exits">סה״כ יציאות אוטומטיות</div>
          <div className="header-block block avg-minutes">ממוצע דקות חניה</div>
          <div className="header-block block sum-refunds">סה״כ זיכויים</div>
          <div className="header-block block sum-discounts">סה״כ הנחות</div>
          <div className="header-block block sum-payments">סה״כ הכנסות</div>
        </div>
        <div className="table-body blocks-wrapper">
          {Object.keys(data).map((key) => {
            const entry = data[key];
            const date = new Date(Number(key)*1000);
            const dateString = `${date.getDate()}/${
              date.getMonth() + 1
            }/${date.getFullYear()},  יום ${daysOfTheWeek[date.getDay()]}`;

            return (
              <div className="row" key={key}>
                <div className="body-block block day-name">{dateString}</div>
                <div className="body-block block sum-entries">{entry.parkings_entries_amount}</div>
                <div className="body-block block sum-entries">{entry.parkings_exits_amount}</div>
                <div className="body-block block sum-entries">{entry.parkings_force_exits_amount}</div>
                <div className="body-block block avg-minutes">{entry.minutes_per_car}</div>
                <div className="body-block block sum-refunds">{entry.credits_amount}</div>
                <div className="body-block block sum-discounts">{entry.discount}</div>
                <div className="body-block block sum-payments">
                  {entry.parkings_total_payment}
                </div>
              </div>
            );
          })}
          {getComputedRow()}
        </div>
      </div>
    </div>
  );
}

export default ParkingLotSummary;
