import React from 'react';

//Assets
import './index.scss';
import inmanage from 'assets/logos/inmanage.png';

function Footer() {

    return (
        <footer className='footer-wrapper'>
            <div className={"footer-title"}>
                2022 עזריאלי כל הזכויות שמורות
            </div>
            <div className={"rights-wrapper"}>
                <img src={inmanage} alt="inmanage" />
                <span>Created by</span>
            </div>
        </footer>
    )
}

export default Footer;