import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import he from "date-fns/locale/he";
import "./CustomDatePicker.scss";
registerLocale("he", he);
function CustomDatePicker(props) {
  const { label, value, minDate, onChange, name, className="", maxDate, isAddTime = false } = props;

  function onChangeDate(val) {
    onChange(name, val);
  }
const handleChangeTime = (date, time) => {
    const [hh, mm, ss] = time.split(":");
    const targetDate = date instanceof Date && !isNaN(date) ? date : new Date();
    targetDate.setHours(Number(hh) || 0, Number(mm) || 0, Number(ss) || 0);
    onChangeDate(targetDate);
};

  return (
    <div className={"date-container " + className}>
      <span className={"date-title"}>{label}</span>
      <DatePicker
        selected={value}
        onChange={onChangeDate}
        showMonthDropdown
        showYearDropdown
        dateFormatCalendar="MMMM"
        dateFormat={`dd/MM/yyyy${isAddTime ? " HH:mm:ss" : ""}`}
        locale={"he"}
        minDate={minDate}
        maxDate={maxDate}
        withPortal
        dropdownMode={"select"}
        rtl
        showTimeInput={isAddTime}
        timeInputLabel={isAddTime && "שעה:"}
        customTimeInput={isAddTime && <CustomTimeInput onChangeCustom={handleChangeTime} />}
      />
    </div>
  );
}

const CustomTimeInput = ({ date, onChangeCustom }) => {
    const value =
        date instanceof Date && !isNaN(date)
            ? // Getting time from Date beacuse `value` comes here without seconds
            date.toLocaleTimeString("it-IT")
            : "";

    return (
        <input
            type="time"
            step="1"
            value={value}
            onChange={(event) => onChangeCustom(date, event.target.value)}
        />
    );
};

export default CustomDatePicker;
