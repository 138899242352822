import React from 'react';

import './index.scss';

const TextInput = (props) => {

    const {
        type,
        name,
        className = '',
        labelClassName = '',
        placeholder = '',
        label = '',
        value = '',
        onChange,
        showError,
        errorMessage = 'empty or undefined errorMessage prop',
        tabIndex} = props;

    const handleChange = (event) => {
        onChange(name, Number(event.target.value));
    };

    return (
        <div className = {'input_wrapper ' + className + ' ' + (showError ? 'error' : '')}>
            { label !== '' && <label className={labelClassName}> { label }:</label> }
            <input type        = { type }
                   name        = { name }
                   placeholder = { placeholder }
                   value       = { value }
                   onChange    = { handleChange }
                   tabIndex    = { tabIndex }
            />
            {showError ? <div className = "error_text">{ errorMessage }</div> : ''}
        </div>
    )
}
export default TextInput;
