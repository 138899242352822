import React from 'react';
import {connect, useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';

//Components
import DesktopMenu from './DesktopMenu';
import BurgerMenu from './BurgerMenu';

//Assets
import './index.scss';
import burger from 'assets/icons/burger.svg';

//routing constants
import * as Routes from 'constants/routes';

//Actions
import Actions from 'redux/actions';

import azrieli_logo from 'assets/icons/azrieli-logo.svg';


function Header() {

    const deviceState = useSelector(store => store.deviceState);
    const allowedModules = useSelector(store => store.userData.modules ?? []);
    const dispatch = useDispatch();
    const handleBurgerClick = () => {
        dispatch(Actions.setBurger(true));
    }

    const initialMenuList = [
        {
            id: 1,
            text: 'נתוני חניה',
            route: Routes.ParkingInfo,
        },
        {
            id: 2,
            text: 'פעולת רכב ידנית',
            route: Routes.ManualCarAction
        },
    ];
    const menuList = initialMenuList.filter(item => allowedModules.includes(item.id));

    return (
        <header className='header-wrapper'>
            <div className="header">
                <div className="burger-icon" onClick={handleBurgerClick}>
                    <img src={burger} alt=""/>
                </div>
                <BurgerMenu data={menuList}/>
                <div className={"logo-header"}>
                    <img src={azrieli_logo} className="logo" alt="logo"/>
                </div>
            </div>
        </header>
    )
}

export default Header;