import React, { useState, useRef, useEffect } from "react";

import DropDown from "../../../assets/icons/drop down.svg";
import CheckMark from "assets/icons/check-mark.svg";
import close from "assets/icons/close-icon.svg";
import "./MultiSelectAutocomplete.scss";

function useOutsideAlerter(ref, onClickOutside) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

function MultiSelectAutocomplete(props) {
  const {
    label = "",
    options,
    disabled = false,
    onChange,
    name,
    dropDownImg = DropDown,
    className = "",
    selectedIds,
    fieldname = "value",
  } = props;
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedItem, setHighlightedItem] = useState(-1);
  const [query, setQuery] = useState("");

  useOutsideAlerter(wrapperRef, onClickOutside);

  const handleClick = (event) => {
    // hanles when user clicks the button
    const newState = !isOpen;
    setIsOpen(newState);
  };

  function onClickOutside() {
    setIsOpen(false);
  }

  const handleKeyUp = (event) => {
    let highlighted_item = highlightedItem;

    switch (event.key) {
      case "ArrowDown":
        highlighted_item + 1 > filteredOptions.length - 1
          ? (highlighted_item = 0)
          : highlighted_item++;

        setHighlightedItem(highlighted_item);
        break;

      case "ArrowUp":
        highlighted_item - 1 < 0
          ? (highlighted_item = filteredOptions.length - 1)
          : highlighted_item--;
        setHighlightedItem(highlighted_item);

        break;

      case "Enter":
        setHighlightedItem(-1);
        const selected_option = filteredOptions[highlighted_item];

        if (!checkForIdInList(highlightedItem)) {
          onChange(name, Number(selected_option.id));
        }
        break;

      case "Escape":
        setIsOpen(false);
        setHighlightedItem(0);
        break;
      default:
        break;
    }
  };
  const handleOptionClick = (event) => {
    // hanles when user clicks an option
    onChange(name, Number(event.target.id));
  };

  function onQueryChange(e) {
    setIsOpen(true);
    const val = e.target.value;
    setQuery(val);
  }

  function filterOptions(options) {
    let filteredOptions = options.filter(
      (option) => option[fieldname].indexOf(query) !== -1
    );

    return filteredOptions;
  }

  function checkForIdInList(id) {
    return selectedIds.includes(Number(id));
  }

  function getList(options) {
    const list = [];
    options.map((item, index) => {
      let activeOption = "";
      let isActive = false;
      let hightlightedOption = "";

      if (checkForIdInList(item.id)) {
        activeOption = "active";
        isActive = true;
      }

      if (index === highlightedItem) {
        hightlightedOption = " highlight";
      }

      list.push(
        <li
          className={"select_option " + activeOption + hightlightedOption}
          key={index}
          id={item.id}
          onMouseDown={handleOptionClick}
        >
          {item[fieldname]}
          {isActive && <img src={CheckMark} className="checked" />}
        </li>
      );
    });
    return list;
  }

  const activeClass = isOpen ? "active" : "";
  const disabledClass = disabled ? "disabled" : "";

  let filteredOptions = query === "" ? options : filterOptions(options);
  return (
    <div className={"multi-select-autocomplete-wrapper " + className}>
      <div
        className={"select-wrapper " + activeClass + " " + disabledClass}
        onKeyUp={handleKeyUp}
        ref={wrapperRef}
      >
        <input
          className="input"
          placeholder={label}
          onClick={handleClick}
          value={query}
          onChange={onQueryChange}
        />
        <img src={dropDownImg} className="dropdown-img"></img>

        <ul className="dropdown-menu">
          {getList(filteredOptions)}
          <div className="button-wrapper">
            <button className="button" onClick={onClickOutside}>
              המשך
            </button>
          </div>
        </ul>
      </div>
      <CloseIcons
        selectedList={selectedIds}
        onClick={onChange}
        options={options}
        name={name}
        fieldname={fieldname}
      />
    </div>
  );
}

export default MultiSelectAutocomplete;

function CloseIcons(props) {
  const { selectedList, options, onClick, name, fieldname } = props;
  return (
    <div className="selected-options-wrapper">
      {selectedList.map((id, index) => {
        const item = options.find(
          (item) => Number(item.id) === Number(id)
        );
        return (
          <div className="selected-option" key={id}>
             <span
              key={index}
              id={id}
              className="icon-wrapper"
              onClick={() => {
                onClick(name, Number(id));
              }}
            >
              <img src={close}></img>
            </span>
            <span className="selected-text">{item[fieldname]}</span>
           
          </div>
        );
      })}
    </div>
  );
}
