import ApiManager from "../index";
import Actions from "redux/actions";
import store from "../../redux";
import * as popups from "constants/popup-types";

class Api extends ApiManager {
  constructor() {
    if (!Api.instance) {
      super();
      Api.instance = this;
    }

    return (Api.instance = this);
  }

  getHostUrl = (props = {}) => {
    const onSuccess = (response) => {
      let api = {
        base_url: response.data.host,
        get_methods: response.data.get_methods,
        get_url: response.data.get_url,
      };
      this._updateApiParams(api);
    };
    return this._execute(props, "getHostUrl", onSuccess);
  };

  generalDeclaration = (props = {}) => {
    const onSuccess = (response) => {
      store.dispatch(Actions.setLoader(false));

      store.dispatch(Actions.setGd(response.data));
    };

    return this._execute(props, "generalDeclaration", onSuccess);
  };

  getParkingData = (props = {}) => {
    const onSuccess = (response) => {
      if (response.data.length === 0) {
        store.dispatch(
          Actions.addPopup({
            type: popups.API_MESSAGE,
            payload: { text: "לא נמצאו תוצאות" },
          })
        );
      }

      store.dispatch(Actions.setParkingSummary(response.data));
    };

    return this._execute(props, "getParkingLotsReport", onSuccess);
  };
  loginUser = (props = {}) => {
    const onSuccess = (response) => {
      store.dispatch(Actions.setUser(response.data));
      store.dispatch(Actions.setLogedIn(true));
    };

    return this._execute(props, "loginUser", onSuccess);
  };

  getUserData = (props = {}) => {
    const onSuccess = (response) => {
      store.dispatch(Actions.setUser(response.data));
      store.dispatch(Actions.setLogedIn(true));
      
    };
    const onFailure = () => {};

    return this._execute(props, "getUserData", onSuccess, onFailure);
  };

  logout = (props = {}) => {
    const onSuccess = (response) => {
      store.dispatch(Actions.setUser({}));
      store.dispatch(Actions.setLogedIn(false));
    };
    return this._execute(props, "logout", onSuccess);
  };
  
  getParkingReportToFile = (props = {}) => {
    return this._execute(props, "getParkingReportToFile");
  };

  closeCarParking = (props = {}) => {
    const onSuccess = (response) => {
      if (response.err !== "") {
        store.dispatch(
            Actions.addPopup({
              type: popups.API_MESSAGE,
              payload: { text: response.err.content },
            })
        );
      }

      store.dispatch(Actions.setActionMessage(response.message));
    };
    const onFailure = (response) => {
      if (response.err !== "") {
        store.dispatch(
            Actions.addPopup({
              type: popups.API_MESSAGE,
              payload: { text: response.err.content },
            })
        );
      }

      store.dispatch(Actions.setActionMessage(response.message));
    }

    return this._execute(props, "closeCarParking", onSuccess, onFailure);
  };

  checkCarParking = (props = {}) => {
    const onSuccess = (response) => {
      if (response.err !== "") {
        store.dispatch(
            Actions.addPopup({
              type: popups.API_MESSAGE,
              payload: { text: response.err.content },
            })
        );
      }

      store.dispatch(Actions.setCarCheckMessage(response.message));
    };
    const onFailure = (response) => {
      if (response.err !== "") {
        store.dispatch(
            Actions.addPopup({
              type: popups.API_MESSAGE,
              payload: { text: response.err.content },
            })
        );
      }

      store.dispatch(Actions.setCarCheckMessage(response.message));
    }

    return this._execute(props, "checkCarParking", onSuccess, onFailure);
  };
}

const instance = new Api();
Object.freeze(instance);
export default instance;
