import React, { Component } from "react";
import { connect } from "react-redux";
import store from "../../redux";
import { withRouter } from "react-router-dom";
import Actions from "redux/actions";

//components
import AnimatedInput from "../../components/forms/animated_input";
import ButtonBasic from "../../components/common/buttons/button_basic";
import Checkbox from "../../components/forms/checkbox";
import * as Popups from "constants/popup-types";
import Recaptcha from "../../components/services/google/recaptcha";

import Validate from "app/validation/validation";
import Api from "api/requests";

//media
import userIcon from "assets/icons/user.svg";
import passwordIcon from "assets/icons/password.svg";
import azrieli_logo from "assets/icons/azrieli-logo.svg";
import checkedImage from "assets/checkbox/checked-checkbox.svg";
import emptyImage from "assets/checkbox/empty-checkbox.svg";

import "./index.scss";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      first_try: true,
      form: {
        email: {
          valid: false,
          rules: ["email", "not_empty"],
          errMsg: "",
        },
        password: {
          valid: false,
          rules: ["not_empty"],
          errMsg: "",
        },
        remember: {
          valid: false,
          rules: ["no_validation"],
          errMsg: "",
        },
      },
    };
  }

  showAPopup = (msg) => {
    let payload = { text: msg };
    this.props.addPopup({ type: Popups.GENERAL_MESSAGE, payload: payload });
  };

  handleLogin = (e) => {
    // let grecaptcha = window.grecaptcha
    // grecaptcha.ready(() => {
    //     grecaptcha.execute('6Ldo2VMbAAAAAMIhQQEj49fYSWSLcuqSl-D7Fjh8', {action: 'submit'}).then((token) => {
    //         let payload = {};
    //         for (let key in this.state.form) {
    //             payload[key] = this.state.form[key].val
    //         }
    //         this.setState({disable: true});
    //         payload['subject'] = this.state.subject.selected;
    //         payload['sub_subject'] = this.state.sub_subject_selected;
    //         payload['g-recaptcha-response'] = token;
    //         this.props.sendNewLead(payload);
    //     })
    // })
    // e.preventDefault();
    let form_valid = true;
    let new_state = { ...this.state.form };
    let validationObj;
    for (let field in this.state.form) {
      validationObj = Validate(
        this.props.Form[field],
        this.state.form[field].rules
      );

      new_state[field].valid = validationObj.valid;
      new_state[field].errMsg = validationObj.msg;

      if (!validationObj.valid) {
        form_valid = false;
      }
    }

    this.setState({ form: new_state, first_try: false });
    if (form_valid) {
      this.loginUser();
    }
  };
  componentDidUpdate() {
    if (this.props.isLoggedIn) {
      this.props.history.push("/");
    }
  }

  loginUser() {
    const payload = {
      email: this.props.Form.email,
      password: this.props.Form.password,
      remember: this.props.Form.remember,
    };    
    Api.loginUser({ payload: payload });
  }

  handleInputChange = (e) => {
    let name = e.target.name;
    let val = e.target.value;
    if (e.target.type == "checkbox") {
      val = e.target.checked;
    }
    let validationObj = Validate(val, this.state.form[name].rules);

    let new_state = { ...this.state };
    new_state.form[name].valid = validationObj.valid;
    new_state.form[name].errMsg = validationObj.msg;

    this.setState(new_state);
    this.props.updateForm({ [name]: val });
  };
  showError = (field) => {
    return !this.state.first_try && !this.state.form[field].valid;
  };

  render() {
    return (
      <div className="login-form">
        <div className={"login-header"}>
          <img src={azrieli_logo} className="login-logo" alt="logo" />
          <h1 className="login-title">עזריאלי חניונים</h1>
        </div>
        <AnimatedInput
          className="login-email-input"
          type="text"
          value={this.props.Form.email}
          onChange={this.handleInputChange}
          name="email"
          placeholder="אימייל"
          image={userIcon}
          errorMessage={this.state.form.email.errMsg}
          showError={this.showError("email")}
          autocomplete={false}
        />
        <AnimatedInput
          className="login-password-input"
          type="password"
          value={this.props.Form.password}
          onChange={this.handleInputChange}
          name="password"
          placeholder="סיסמה"
          image={passwordIcon}
          errorMessage={this.state.form.password.errMsg}
          showError={this.showError("password")}
          autocomplete={false}

        />
        <Recaptcha />
        <ButtonBasic
          className="login-btn"
          btnText="התחברות"
          onClick={this.handleLogin}
        />
        <Checkbox
          type="checkbox"
          className="login-checkbox"
          name="remember"
          id="remember"
          label="זכור אותי"
          checked={this.props.Form.remember}
          onChange={this.handleInputChange}
          checkedImage={checkedImage}
          emptyImage={emptyImage}
        />
      </div>
    );
  }
}

//connect to redux store
const mapStateToProps = (store) => {
  return {
    deviceState: store.deviceState,
    Gd: store.gd,
    Form: store.userForm,
    isLoggedIn: store.logginState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addPopup: (payload) => dispatch(Actions.addPopup(payload)),
    updateForm: (form) => dispatch(Actions.updateUserForm(form)),
  };
};

//connect to redux store - maps dispatch and redux state to props
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(Login)
);
