import { createSlice } from "@reduxjs/toolkit";

let Slices = [];
let formReducers = {};
let formActions = {};

export const contactSlice = createSlice({
  name: "contactForm",
  initialState: {},
  reducers: {
    updateContactForm: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetContactForm: () => {
      return {};
    },
  },
});
Slices.push(contactSlice);

export const parkingForm = createSlice({
  name: "parkingForm",
  initialState: {
    startDate: new Date(),
    endDate: new Date(),
    selectedCarparks: [],
  },
  reducers: {
    updateParkingForm: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});
Slices.push(parkingForm);

export const actionForm = createSlice({
  name: "actionForm",
  initialState: {
    licenseNumber: "",
    exitTime: new Date(),
    carParkId: -1,
  },
  reducers: {
    updateActionForm: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});
Slices.push(actionForm);

export const carCheckForm = createSlice({
  name: "carCheckForm",
  initialState: {
    licenseNumber: "",
  },
  reducers: {
    updateCarCheckForm: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});
Slices.push(carCheckForm);

export const userForm = createSlice({
  name: "userForm",
  initialState: {},
  reducers: {
    updateUserForm: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});
Slices.push(userForm);
//build export objects
for (const Slice of Slices) {
  formActions = { ...formActions, ...Slice.actions };
  let reducer = { [Slice.name]: Slice.reducer };
  formReducers = { ...formReducers, ...reducer };
}

export { formActions };
export { formReducers };
