import React, { Component } from "react";
import { connect } from "react-redux";

import Validate from "app/validation/validation";
import Actions from "redux/actions";

import CustomDatePicker from "components/forms/CustomDatePicker/CustomDatePicker";
import MultiSelectAutocomplete from "components/forms/MultiSelectAutocomplete/MultiSelectAutocomplete";

import "./index.scss";
import ExcelIcon from 'assets/icons/excel-icon.svg';
import PrinterIcon from 'assets/icons/printer-icon.svg';
import Api from "api/requests";
import ParkingSummary from "containers/ParkingSummary/ParkingSummary";
import './print.scss';
class ParkingInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      carparks: [],
    };
  }

  componentDidMount() {
    const carparks = [];
    for (const key in this.props.parkingLots) {
      const parkingLot = this.props.parkingLots[key];
      const isAllowed = this.isAllowedParkingLot(key);
      if (isAllowed) {
        const carPark = {
          value: parkingLot.title,
          id: key,
        };
        carparks.push(carPark);
      }
    }
    this.setState({ carparks: carparks });
  }

  isAllowedParkingLot = (id) => {
    for (const key in this.props.allowedParkingLots) {
      const allowedParkingLot = this.props.allowedParkingLots[key];
      if (Number(allowedParkingLot) === Number(id)) {
        return true;
      }
    }
    return false;
  };

  onChange = (field, value) => {
    this.props.updateForm({ [field]: value });
  };

  onChangeSelect = (field, id) => {
    const prevValue = [...this.props.form[field]];
    if (prevValue.includes(Number(id))) {
      const newValue = prevValue.filter(function (value) {
        return value != id;
      });
      this.props.updateForm({ [field]: newValue });
    } else {
      this.props.updateForm({ [field]: [...prevValue, id] });
    }
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    this.getParkingSummary();
  };

  toTimestamp = (strDate) => {
    const dt = Date.parse(strDate);
    return dt / 1000;
  };

  getParkingPayload = () => {
    const parkingIds = {};
    let count = 0;
    for (const key in this.props.form.selectedCarparks) {
      const carparks = this.props.form.selectedCarparks[key];
      parkingIds[`parkingIds[${count}]`] = carparks;
      count++;
    }

    return {
      from: this.toTimestamp(this.props.form.startDate),
      to: this.toTimestamp(this.props.form.endDate),
      ...parkingIds,
    };
  }

  getDataLength = () => {
    const hasData = typeof this.props.ParkingSummaryData === 'object' &&
        typeof this.props.ParkingSummaryData?.parking === 'object' &&
        Object.keys(this.props.ParkingSummaryData.parking).length > 0;
    return hasData ? Object.keys(this.props.ParkingSummaryData.parking).length : 0;
  }

  getParkingSummary = () => {
    const payload = this.getParkingPayload();
    this.props.setLoader(true);

    Api.getParkingData({ payload: payload }).then(() => {
      this.props.setLoader(false);
    });
  };

  downloadWithUrl = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.click();
  }

  logout = () => {
    Api.logout();
  };

  exportToCSV = () => {
    const payload = this.getParkingPayload();
    const length = this.getDataLength();
    if (length === 0) {
      alert('יש לבחור חניונים לפני הפנקת קובץ CSV');
    }
    else {
      this.props.setLoader(true);
      Api.getParkingReportToFile({ payload: payload }).then((res) => {
        this.props.setLoader(false);
        if (res.status === 1) {
          this.downloadWithUrl(res.url);
        }
      });
    }
  };

  openPrint = () => {
    const length = this.getDataLength();
    if (length === 0) {
      alert('יש לבחור חניונים לפני הדפסה');
    }
    else {
      window.print();
    }
  };

  render() {
    return (
        <div className="home">
          <div className="options">
            <button className="logout" onClick={this.logout}>התנתקות</button>
            <div className="export-options">
              <button className="export-option excel" onClick={this.exportToCSV}>
                <span className="export-title">הנפק דוח CSV</span>
                <div className="export-option-icon">
                  <img src={ExcelIcon}/>
                </div>
              </button>
              <button className="export-option print" onClick={this.openPrint}>
                <span className="export-title">הדפסה</span>
                <div className="export-option-icon">
                  <img src={PrinterIcon}/>
                </div>
              </button>
            </div>

          </div>
          <div className={"inputs-container"}>
            <div className="inputs-wrapper">
              <CustomDatePicker
                  className="field "
                  label={"מתאריך:"}
                  value={this.props.form.startDate}
                  onChange={this.onChange}
                  maxDate={this.props.form.endDate}
                  name={"startDate"}
              />
              <CustomDatePicker
                  className="field "
                  label={"עד תאריך:"}
                  value={this.props.form.endDate}
                  onChange={this.onChange}
                  minDate={this.props.form.startDate}
                  name={"endDate"}
              />
              <MultiSelectAutocomplete
                  label={"חניון"}
                  options={this.state.carparks}
                  selectedIds={this.props.form.selectedCarparks}
                  name={"selectedCarparks"}
                  fieldname={"value"}
                  className={"choose-parking-garage field"}
                  onChange={this.onChangeSelect}
              />
            </div>

            <button className={"send-btn"} onClick={this.onFormSubmit}>
              שלח
            </button>
          </div>

          <div className="parking-summary">
            {Object.keys(this.props.ParkingSummaryData).length > 0 && (
                <ParkingSummary
                    data={this.props.ParkingSummaryData}
                    parkings={this.state.carparks}
                />
            )}
          </div>
        </div>
    );
  }
}

//add GD categories and deviceState to props
const mapStateToProps = (store) => {
  return {
    deviceState: store.deviceState,
    Gd: store.gd,
    form: store.parkingForm,
    isLoggedIn: store.isLoggedIn,
    ParkingSummaryData: store.parkingSummary,
    parkingLots: store.gd.parkingArr,
    allowedParkingLots: store.userData.parking_manager,
  };
};
//map a doLgin function to props
const mapDispatchToProps = (dispatch) => {
  return {
    updateForm: (form) => dispatch(Actions.updateParkingForm(form)),
    setLoader: (state) => dispatch(Actions.setLoader(state)),
  };
};

//connect to redux store - maps dispatch and redux state to props
export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false,
})(ParkingInfo);
